/**
 * This file registers all the sliders.
 *
 * @package schnabl
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */
//import 'slick-carousel';
import 'justifiedGallery';
import '../vendor/jquery.swipebox.js';

export function sliderConfiguration() {
    $('.slider').slick({
        dots: true,
        lazyLoad: 'ondemand',
        infinite: true,
        speed: 800,
        arrows: false,
        fade: true,
        rows: 0,
        cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
        adaptiveHeight: true
    });
}

export function schnablJustifiedGallery() {
    $(".justified-gallery").justifiedGallery({
        rowHeight: 350,
        maxRowHeight: 350,
        margins: 0,
        captions: false,
        border: -1,
    }).on('jg.complete', (e) => {
        $('.swipebox-img').swipebox();
    });
}
