import 'bootstrap';

// Template
import { resetPage } from "./theme/resetPage";
import { scrollBottom } from './theme/scroll';
import { schnablJustifiedGallery } from "./theme/gallery";

// ================================ //
// ================================ //
// ================================ //

// jQuery wrapper to run on ready state
$(document).ready(() => {
    resetPage();
    scrollBottom();
    schnablJustifiedGallery();
    //sliderConfiguration();
});

// ================================ //
// ================================ //
// ================================ //

// jQuery wrapper to run on resize
$(window).on('resize', () => {
    resetPage();
});

// ================================ //
// ================================ //
// ================================ //
