/**
 * Scroll-to-Bottom button
 *
 * @package schnabl
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */

export function scrollBottom() {
    const butt = document.getElementById('hero-scroll-bottom');

    if (butt) {
        butt.addEventListener('click', (e) => {
            e.preventDefault();

            window.scrollBy({
                top: (butt.parentNode.parentNode.clientHeight - document.documentElement.scrollTop),
                left: 0,
                behavior: "smooth"
            });
        }, false);
    }
}

export function pageScroll() {
    $('.on-page-link').on('click', function (e) {
        e.preventDefault();

        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                    var $target = $(target);

                    $target.focus();

                    if ($target.is(":focus")) {
                        return false;
                    } else {
                        $target.attr('tabindex', '-1');
                        $target.focus();
                    }
                });
            }
        } else {
            document.location.href = $(this).attr('href');
        }
    });
}
